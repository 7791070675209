import { render, staticRenderFns } from "./Interactions.vue?vue&type=template&id=36c2b2bd&"
import script from "./Interactions.vue?vue&type=script&lang=js&"
export * from "./Interactions.vue?vue&type=script&lang=js&"
import style0 from "./Interactions.vue?vue&type=style&index=0&id=36c2b2bd&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports