<template>
  <span
    class="contract-component"
    @click="() => toggleContract()"
    v-if="
      (
        contract.type === 'letter'
        && (
          !contract.url
          || !contract.url.includes('.pdf')
        ))
      || contract.type === 'digital'"
  >
    <slot />
    <button type="button" class="btn btn-warning view-contract-button">
      View
    </button>
    <template v-if="showContract">
      <ViewPDFButton
        v-if="contract.type === 'digital'"
        :pdfURL="contractURL"
        :show="showContract"
        :modalTitle="'Akkoordverklaring'"
      />
      <!-- <ViewPDFButton
        v-if="contract.type === 'letter'"
        :pdfURL="contract.url.replace('dl=1', 'dl=0')"
        :show="showContract"
        :modalTitle="'Akkoordverklaring'"
      /> -->
      <ContractImage
        v-if="contract.type === 'letter' || (contract.url && !contract.url.includes('.pdf'))"
        :imageURL="contract.url"
        :show="showContract"
      />
    </template>
  </span>
</template>

<script>
import ViewPDFButton from '@/components/ViewPDFButton.vue';
import ContractImage from '@/components/ContractImage.vue';
// import DownloadDocumentIcon from '@/components/icons/DownloadDocumentIcon.vue';
import * as requests from '@/common/requests';

export default {
  props: {
    contract: Object,
    address: String,
  },
  components: {
    // DownloadDocumentIcon,
    ViewPDFButton,
    ContractImage,
  },
  data() {
    return {
      contractURL: undefined,
      showContract: false,
    };
  },
  methods: {
    async toggleContract() {
      if (this.contractURL === undefined) {
        await this.getContractURL();
      }
      this.showContract = !this.showContract;

      if (this.showContract) {
        this.$events.emit('interaction', {
          type: 'view',
          context: 'akkoordverklaring',
          value: this.contractURL,
        });
      }
    },

    async getContractURL() {
      const response = await requests.getContract(
        this.address,
        this.contract.type,
        this.contract.id,
        this.contract.url,
      );
      if (response.status !== 'error') {
        this.contractURL = response.data;
      }
    },

    async getContractURLLetter() {
      const response = await requests.getContractBlob(
        this.address,
        this.contract.type,
        this.contract.id,
        this.contract.url,
      );
      if (response.status !== 'error') {
        this.contractURL = response.data;
      }
    },
  },
};
</script>

<style>
  .contract-component {
    cursor: pointer;
  }
  .view-contract-button {
    text-decoration: none;
    font-size: 0.5rem;
    padding: 0 0.1rem;
    margin-left: 0.25rem;
  }
</style>
