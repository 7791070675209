<template>
  <input
    placeholder="Zoeken..."
    type="text"
    name="filterValue"
    class="border border-grey-300 rounded-sm h-7 px-2"
    :value="filterValue"
    @input="onChange"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Filters",
  data() {
    return {
      timeoutId: null,
      filterValue: "",
    };
  },
  computed: {
    ...mapState(["userEmail", "isLoggedIn", "selectedWoco", "user"]),
  },
  watch: {
    selectedWoco() {
      this.filterValue = "";
    },
  },
  methods: {
    onChange({ target }) {
      this.filterValue = target.value;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.$events.emit("interaction", {
          type: "filter",
          context: "clients",
          value: `woco=${this.selectedWoco}&value=${this.filterValue}`,
        });
        this.$root.$emit("onFilterUpdate", this.filterValue);
      }, 500);
    },
  },
  created() {},
};
</script>
