<template>
  <div
    class="info-filter"
    tabindex="0"
    @blur="(e) => onBlur(e)"
    ref="infofilterdiv"
  >
    <b>Installation date</b>

    <input
      ref="infoFilterInput1"
      type="date"
      v-model="fromDate"
      @blur="(e) => onBlur(e)"
    >
    t/m
    <input
      type="date"
      v-model="toDate"
      @blur="(e) => onBlur(e)"
    >
    <div class="flex flex-col gap-2">
      <div class="status-select">
        <b class="mt-2">Status</b>
        <multiselect v-model="value" :options="statuses" :multiple="true" :close-on-select="false" :clear-on-select="false" :showLabels="false" placeholder="Select status">
          <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} selected</span></template>
        </multiselect>
      </div>

      <div class="flex flex-row gap-1 justify-start">  
        <e-button 
          type="button" 
          @click="applyInfoFilter()"
          @blur="(e) => onBlur(e)"
          color="green"
        >
          Filter
        </e-button>
        <e-button 
          type="button" 
          @click="clearInfoFilter()"
          @blur="(e) => onBlur(e)"
          color="gray"
        >
          Clear
        </e-button>

      </div>
    </div>  
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import EButton from '@/components/EButton.vue'

export default {
  components: { 
    Multiselect,
    EButton,
  },
  props: {
    onFilterChange: Function,
    isShown: Boolean,
  },
  data() {
    return {
      fromDate: undefined,
      toDate: undefined,
      status: undefined,
      value: [],
      statuses: ['approved', 'cancelled', 'assigned', 'created', 'ongoing', 'finished', 'rejected'],
    };
  },

  watch: {
    isShown(shown) {
      if (shown) {
        this.$refs.infoFilterInput1.focus();
      }
    },
  },

  methods: {
    applyInfoFilter() {
      this.onFilterChange(this.fromDate, this.toDate, this.value);
      this.$emit('blur');
    },
    clearInfoFilter() {
      this.fromDate = undefined;
      this.toDate = undefined;
      this.value = [];
    },
    onBlur(e) {
      if (e.relatedTarget === null) {
        this.$emit('blur', e);
      }
    },
  },

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .info-filter {
    font-weight: normal;
    width: 11rem;
    z-index: 5000;
    background-color: white;
    color: black;
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
  }

  .info-filter:focus {
    outline: 0px solid transparent;
  }

  .info-filter-button {
    font-size: 0.9rem;
    padding: 0 0.5rem;
    margin: 0.5rem 0rem;
  }

  .info-filter-button:not(:first-child) {
    margin-left: 0.25rem;
  }

  .status-select {
    display: flex;
    flex-direction: column;
  }

  .status-select {
    margin-right: 0.6rem;
  }
</style>
