<template>
  <div class="wrapper">
    <!-- <label for="wocos">Select a woco:</label> -->
    <select
      v-if="user.wocos.length > 1"
      name="wocos"
      class="text-black bg-gray-200 p-1 rounded"
      id="wocos"
      @change="onChange"
    >
      <option value="_" :selected="selectedWoco !== null">Selecteer een woco</option>
      <option
        v-for="woco in user.wocos"
        :key="woco.name"
        :value="woco.name"
        :selected="selectedWoco === woco.name"
      >
        {{woco.name}}
      </option>
    </select>
    <select
      v-else-if="user.wocos.length === 1"
      name="wocos"
      class="custom-select"
      id="wocos"
      @change="onChange"
      disabled
    >
      <option
        v-for="woco in user.wocos"
        :key="woco.name"
        :value="woco.name"
        :selected="selectedWoco === woco.name"
      >
        {{woco.name}}
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DropDown',
  data() {
    return {
      wocos: [],
    };
  },
  computed: {
    ...mapState(['selectedWoco', 'user', 'selectedYear']),
  },
  watch: {
    user() {
      if (this.user.wocos.length === 1) {
        this.setSelectedWoco(this.user.wocos[0].name);
      }
    },
  },
  methods: {
    ...mapMutations(['setSelectedWoco', 'setSelectedYear']),
    onChange({ target }) {
      this.setSelectedWoco(target.value === '_' ? null : target.value);

      // Reset year if the currently selected one is not available for the new woco
      const woco = this.user.wocos.find((w) => w.name === this.selectedWoco);

      if (woco === undefined || !Object.keys(woco.available_years).includes(this.selectedYear)) {
        this.setSelectedYear(Object.keys(woco.available_years)[0]);
      }

      this.$events.emit('interaction', {
        type: 'select',
        context: 'dropdown',
        value: this.selectedWoco,
      });
    },
  },
};
</script>

<style>
</style>
