var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col"},[(_vm.showStatusDropdown)?_c('div',{staticClass:"popover-placeholder"},[_c('Popover',{attrs:{"offset":{ y: 15 },"align":{ x: 'center' },"containerClass":"wrapper"}},[_c('div',{staticClass:"flex gap-1 flex-col"},[_vm._l((['installation', 'inspection', 'sales', 'design']),function(stage){return [(Object.keys(_vm.taskData).includes(stage))?_c('div',{key:stage,class:(
              'rounded cursor-pointer w-32 text-sm p-2 '
              + `bg-${_vm.statusColors[_vm.statusTranslation[stage]]}-300 hover:bg-${_vm.statusColors[_vm.statusTranslation[stage]]}-200`
              + `${_vm.selectedStatus === stage ? ' font-bold ' : ''}`
            ),on:{"click":() => _vm.selectHistory(stage)}},[_vm._v(" "+_vm._s(_vm.statusTranslation[stage])+" ")]):_vm._e()]})],2)])],1):_vm._e(),(_vm.taskData.status !== '')?_c('div',{ref:"toggleButton",class:(
      'rounded flex flex-row p-1 cursor-pointer '
      + `bg-${_vm.statusColors[(
        _vm.selectedStatusHistory === undefined
        ? _vm.taskData.status_translated
        : _vm.statusTranslation[_vm.selectedStatusHistory]
      )]}-300`
    ),on:{"click":() => _vm.onClick({ target: _vm.$refs.toggleButton })}},[_c('div',[(_vm.shouldShowSelector)?_c('i',{staticClass:"fas fa-caret-down"}):_vm._e()]),_c('div',{staticClass:"status-dropdown-inner",domProps:{"innerHTML":_vm._s((
        _vm.selectedStatusHistory === undefined
        ? _vm.taskData.status_translated
        : _vm.statusTranslation[_vm.selectedStatusHistory]
      ))}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }