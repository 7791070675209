var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (
      _vm.contract.type === 'letter'
      && (
        !_vm.contract.url
        || !_vm.contract.url.includes('.pdf')
      ))
    || _vm.contract.type === 'digital')?_c('span',{staticClass:"contract-component",on:{"click":() => _vm.toggleContract()}},[_vm._t("default"),_c('button',{staticClass:"btn btn-warning view-contract-button",attrs:{"type":"button"}},[_vm._v(" View ")]),(_vm.showContract)?[(_vm.contract.type === 'digital')?_c('ViewPDFButton',{attrs:{"pdfURL":_vm.contractURL,"show":_vm.showContract,"modalTitle":'Akkoordverklaring'}}):_vm._e(),(_vm.contract.type === 'letter' || (_vm.contract.url && !_vm.contract.url.includes('.pdf')))?_c('ContractImage',{attrs:{"imageURL":_vm.contract.url,"show":_vm.showContract}}):_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }