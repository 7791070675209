<template>
  <div class="drag-and-drop">
    <input
      id="file"
      type="file"
      class="form-control-file"
      @change="fileChange"
      @dragover.prevent="onDragOver"
      @dragenter="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDragLeave"
      accept=".xls,.xlsx"
      :class="{'is-drag-over': isDragOver}"
    >
  </div>
</template>

<script>
export default {
  props: {
    fileChange: Function,
  },
  name: 'DragAndDrop',
  data() {
    return {
      isDragOver: false,
    };
  },
  methods: {
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
  },
};
</script>

<style lang="postcss">
.drag-and-drop {
  .form-control-file {
    padding: 30px;
    padding-left: 90px;
    padding-bottom: 50px;
    padding-right: 0;
    border: 2px dashed #a2a2a2;

    &:after {
      content: "Or drag it here.";
      margin: 0 auto;
      color: #505050;
      font-weight: 600;
      text-transform: capitalize;
      text-align: center;
      bottom: 43px;
      left: 180px;
      position: absolute;
      font-size: 0.9rem;
    }
  }
  .is-drag-over {
    background-color: #e2e2e2;
  }
}
</style>
