<template>
  <a
    type="button"
    class="btn btn-primary download-blob-button"
    ref="downloadblobbutton"
    :disabled="loading"
    @click="onClick"
  >
    <span v-if="loading">Verwerken...</span>
    <span v-else>Download</span>
  </a>
</template>

<script>
import * as requests from '@/common/requests';

export default {
  props: {
    contract: Object,
    address: String,
    downloadLink: String,
  },
  data() {
    return {
      loading: false,
      contractBlob: undefined,
      blobLink: (
        (this.address !== undefined && this.contract !== undefined)
          ? `/api/household/${this.address}/contract/${this.contract.type}/${this.contract.id}/${this.contract.file_name}/downloadable/`
          : undefined
      ),
    };
  },
  methods: {
    async getAndDownloadBlob() {
      this.$refs.downloadblobbutton.click();
    },
    async onClick(downloadFile = true) {
      this.loading = true;
      let fileType = 'pdf';
      if (this.contract.url && this.contract.url.includes('png')) {
        fileType = 'png';
      }
      if (this.contract.url && this.contract.url.includes('jpeg')) {
        fileType = 'jpeg';
      }
      if (this.contract.url && this.contract.url.includes('jpg')) {
        fileType = 'jpg';
      }
      if (this.contract.url && this.contract.url.includes('docx')) {
        fileType = 'docx';
      }

      const url = this.contract.url
        ? this.contract.url
        : this.blobLink;
      
      const response = await fetch(
        this.blobLink,
      );

      if (response.status !== 200) {
        console.warn('Failed to download PDF');
        return null;
      }

      const blob = await response.blob();

      const file = new File([blob], `${this.address}.${fileType}`);

      if (downloadFile) {
        const anchor = document.createElement('a');

        const blobDownloadLink = window.URL.createObjectURL(file);
        anchor.href = blobDownloadLink;

        anchor.download = `${this.address}.${fileType}`;
        anchor.click();

        window.URL.revokeObjectURL(blobDownloadLink);
        anchor.remove();

        this.loading = false;
      }

      return file;
    },
  },
};
</script>

<style>
  .contract-component {
    cursor: pointer;
  }
  .download-blob-button {
    text-decoration: none;
    font-size: 0.5rem;
    padding: 0 0.1rem;
    margin-left: 0.25rem;
  }
</style>
