<template>
    <div
      class="field-with-history"
    >
      <span
        @mouseenter="historyButtonMouseEnter"
        @mouseleave="historyButtonMouseLeave"
        class="pointer field-with-history-span"
        ref="fieldContent"
      ><slot></slot></span>
      <span v-if="isNew" class="new">new</span>
      <!-- <slot /> -->
      <Popover
        v-if="showFieldHistory && !blockPopover"
        :align="{ x: 'left' }"
        :target="$refs.fieldContent"
        containerClass="wrapper"
      >
        <span v-if="sortedFieldHistory.length === 0"><i>Loading...</i></span>
        <div v-else v-for="change in sortedFieldHistory" :key="change.value + change.at">
          <b>{{change.value}}</b> - by {{change.by}}
          <i>(at {{change.at | asDate}})</i>
        </div>
      </Popover>
    </div>
</template>

<script>
import Popover from '@/components/Popover.vue';

export default {
  props: {
    onHistoryShow: Function,
    fieldHistory: Array,
    content: String,
    isNew: Boolean,
  },
  components: {
    Popover,
  },
  data() {
    return {
      showFieldHistory: false,
      onHistoryShowTimeoutId: null,
      requestHistoryDelay: 200, // ms
      blockPopover: false,
    };
  },
  computed: {
    sortedFieldHistory() {
      const sortedFieldHistory = [...this.fieldHistory];
      sortedFieldHistory.reverse();
      return sortedFieldHistory;
    },
  },
  methods: {
    historyButtonMouseEnter() {
      this.showFieldHistory = true;
      this.onHistoryShowTimeoutId = setTimeout(() => {
        this.onHistoryShow();
      }, this.requestHistoryDelay);
    },
    historyButtonMouseLeave() {
      if (this.blockPopover && !this.showFieldHistory) return;
      clearTimeout(this.onHistoryShowTimeoutId);
      this.showFieldHistory = false;
    },
    onScrollStart() {
      this.blockPopover = true;
    },
    onScrollEnd() {
      this.blockPopover = false;
    },
  },
  filters: {
    asDate(t) {
      return new Date(t).toLocaleString();
    },
  },
  mounted() {
    this.$events.on('scroll-start', this.onScrollStart);
    this.$events.on('scroll-end', this.onScrollEnd);
  },
  beforeDestroy() {
    this.$events.off('scroll-start', this.onScrollStart);
    this.$events.off('scroll-end', this.onScrollEnd);
  },
};
</script>

<style lang="postcss">
.field-with-history {
  display: flex;

  .new {
    background-color: #fc5959;
    color: whitesmoke;
    border-radius: 4px;
    margin-left: auto;
    padding: 0 3px;
    font-size: 0.6rem;
    align-self: flex-start;
  }
}

.field-with-history-span {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #ececec #fff;
}

.field-with-history-span::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  color: #868686; /* Fallback */
}

.field-with-history-span::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.field-with-history-span::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
</style>
