<template>
  <div class="m-2">
    <Alert :alerts="alertMessages" positionClass="position-fixed"/>
    <ExcelInput
      :close="() => {}"
      :updateClientsList="() => {}"
      :setAlertMessages="m => alertMessages = m"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ExcelInput from '@/components/ExcelInput.vue';
import Alert from '@/components/Alert.vue';

const alertMessages = ref([])
</script>
