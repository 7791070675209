<template>
  <div>
    <!-- <label for="wocos">Select a woco:</label> -->
    <select
      name="groups"
      class="text-black bg-gray-200 p-1 rounded w-64"
      id="groups"
      @change="onChange"
    >
      <option
        v-for="group in groups"
        :key="group"
        :value="group"
        :selected="selectedGroup === group"
      >
        {{group}}
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DropDownGroup',
  computed: {
    ...mapState(['user', 'selectedGroup', 'availableGroups']),
    groups() {
      return ['Alle Groepen', ...this.availableGroups.map((group) => group.name)];
    },
  },
  methods: {
    ...mapMutations(['setSelectedGroup']),
    onChange({ target }) {
      this.setSelectedGroup(target.value);
    },
  },
  // mounted() {
  //   const woco = this.user.wocos.find((w) => w.name === this.selectedWoco);

  //   if (!this.selectedProject
  //     || !woco.available_years[this.selectedYear].includes(this.selectedProject)) {
  //     this.setSelectedProject('Alle Projecten');
  //   }
  // },
};
</script>
