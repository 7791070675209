<template>
  <div>
    <AppHeader :selectedScreen="selectedScreen" :enabled="navigationEnabled" />
    <router-view
      @loginChange="verifyStatus"
      @toggleNavigation="toggleNavigation"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as auth from '@/common/auth';
import { postUserInteraction } from '@/common/logging';
import AppHeader from '@/components/AppHeader.vue';
import "./assets/scss/index.css"
// import Modal from '@/components/Modal.vue'
// import Spinner from '@/components/Spinner.vue'

export default {
  name: 'App',
  data() {
    return {
      selectedScreen: undefined,
      navigationEnabled: true,
    };
  },
  components: {
    AppHeader,
    // Modal,
    // Spinner,
  },
  computed: {
    ...mapState(['isLoggedIn', 'userEmail', 'user']),
  },
  methods: {
    ...mapMutations(['setLoggedIn', 'setUser']),
    verifyStatus() {
      auth.verifyUserLoginStatus();
    },
    toggleNavigation(enabled) {
      this.navigationEnabled = enabled;
    },
  },
  created() {
    auth.verifyUserLoginStatus();
    this.$events.on('interaction', postUserInteraction);
  },
  watch: {
    $route: {
      handler(route) {
        this.selectedScreen = route.name;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$events.off('interaction', postUserInteraction);
  },
};
</script>

<style lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
