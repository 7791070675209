var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"bi bi-file-earmark-arrow-down",attrs:{"width":"1em","height":"1em","viewBox":"0 0 16 16","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":(
        'M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2'
        + ' 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'
    )}}),_c('path',{attrs:{"d":"M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":(
        'M8 6a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5'
        + ' 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 10.293V6.5A.5.5 0 0 1 8 6z'
        )}})])
}
var staticRenderFns = []

export { render, staticRenderFns }