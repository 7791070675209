<template>
  <th scope="col" style="width: 80px" class="bg-black">
    <div style="display: flex;" @mouseleave="closePopovers" class="table-action-wrapper">
      <input type="checkbox"
        style="margin: 0 0.25rem;"
        :checked="selectAllRows"
        @change="onSelectAllRows"
      >
      <div
        class="acties-menu-caret"
        @click="() => showMultipleActions = !showMultipleActions"
        style="display: flex; align-items: center;"
      >
        Acties
        <i class="fas fa-caret-down" style="margin: 0 3px; font-size: 0.9rem;"></i>
      </div>
      <Popover
        v-if="showMultipleActions"
        containerClass="table-action-wrapper"
      >
        <div class="multiple-action-option" @click="downloadContracts">
          Akkoordverklaring downloaden {{ downloadableContracts }}
        </div>
        <div
          class="multiple-action-option"
          @click="downloadAsbuilts"
        >
          Asbuilts downloaden {{ downloadableAsbuilts }}
        </div>
        <div
          v-if="!!user.permissions['download_docs']"
          class="multiple-action-option"
          @click="downloadLetters"
        >
          Letters downloaden {{ downloadableLetters }}
        </div>
        <div
          v-if="!!user.permissions['download_docs']"
          class="multiple-action-option"
          @mouseenter="() => showTemplateOptions = true"
          @mouseleave="() => showTemplateOptions = false"
        >
          Pas letter template toe aan selectie {{ downloadableLetters }}
          <Popover
            v-if="showTemplateOptions"
            :target="$refs.popoverTarget"
            containerClass="multiple-action-option"
          >
            <div
              v-for="template in documentTemplates"
              :key="`template-${template.id}`"
              class="multiple-action-option"
              @click="onClickTemplate(template)"
            >
              {{ template.name }}
            </div>
          </Popover>
        </div>
      </Popover>
    </div>
  </th>
</template>

<script>
import Popover from '@/components/Popover.vue';
import { mapState } from 'vuex';

export default {
  name: 'TableActions',
  components: { Popover },
  props: {
    selectAllRows: Boolean,
    downloadContracts: Function,
    downloadAsbuilts: Function,
    downloadLetters: Function,
    onSelectAllRows: Function,
    downloadableContracts: String,
    downloadableAsbuilts: String,
    downloadableLetters: String,
    applyTemplates: Function,
  },
  data() {
    return {
      showMultipleActions: false,
      showTemplateOptions: false,
    };
  },
  computed: {
    ...mapState(['documentTemplates', 'user']),
  },
  methods: {
    closePopovers() {
      this.showMultipleActions = false;
      this.showTemplateOptions = false;
    },
    onClickTemplate(template) {
      this.closePopovers();
      this.applyTemplates(template);
    },
  },
};
</script>

<style>
.acties-menu-caret {
  cursor: pointer;
}

.multiple-action-option {
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 0.5rem;
}

.multiple-action-option:hover {
  background-color: #486f14;
  color: white;
}

.multiple-action-option.selected {
  background-color: #486f14;
  color: white;
}
</style>
