export function snakeToCamelCase(str) {
  return str
    .split('_')
    .map((substr, i) => (i === 0 ? substr : substr[0].toUpperCase() + substr.slice(1)))
    .join('');
}

export function objectToCamelCase(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => (
    { ...acc, [snakeToCamelCase(key)]: value }
  ), {});
}

// Accesses a nested key 'example.nested.key' safely.
// Equivalent to example?.nested?.key in ecma262.
export function getNestedKey(obj, nestedKey, defaultValue) {
  const allKeys = nestedKey.split('.');
  let acc = { ...obj };
  let result = defaultValue;
  allKeys.forEach((key) => {
    if (acc[key] !== undefined) {
      if (Array.isArray(acc[key])) {
        result = acc[key];
      } else if (typeof acc[key] === 'object') {
        acc = acc[key];
      } else {
        result = acc[key];
      }
    }
  });
  return result;
}

export function convertArrayToObject(arr, key, value) {
  const result = {};
  arr.forEach((item) => { result[item[key]] = item[value]; });

  return result;
}
