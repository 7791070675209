<template>
  <span>
    <button
      type="button"
      class="btn btn-primary download-asbuilt-button"
      ref="downloadasbuiltbutton"
      @click="onClick"
    >
      {{ isLoading ? 'Verwerken...' : 'Download' }}
    </button>
    <embed
      v-if="asbuiltLink !== undefined"
      ref="buttonlink"
      class="asbuilt-embed"
      :src="asbuiltLink"
      style="width:0.1px;height:0.1px;background-color:transparent;"
    >
  </span>
</template>

<script>
import * as requests from '@/common/requests';

export default {
  props: {
    asbuiltFilename: String,
    addressPostcode: String,
  },
  data() {
    return {
      asbuiltLink: undefined,
      isLoading: false,
    };
  },
  methods: {
    async getAndDownloadAsbuilt() {
      this.asbuiltLink = undefined;
      this.$refs.downloadasbuiltbutton.disabled = true;
      this.isLoading = true;
      const response = await requests.getAsbuilt(
        this.addressPostcode,
        this.asbuiltFilename,
      );
      if (response.status !== 'error') {
        // this.asbuiltLink = response.data;
        const blobResponse = await fetch(response.data);
        const blob = await blobResponse.blob();
        const blobDownloadLink = window.URL.createObjectURL(blob);
        // window.location.assign(blobDownloadLink);

        // Use <a> approach to set filename properly
        const anchor = document.createElement('a');
        anchor.style = 'display: none;';
        anchor.href = blobDownloadLink;
        anchor.download = this.asbuiltFilename;
        anchor.click();
        window.URL.revokeObjectURL(blobDownloadLink);
        anchor.remove();
      }
      this.isLoading = false;
      this.$refs.downloadasbuiltbutton.disabled = false;
    },
    onClick() {
      this.$events.emit('interaction', {
        type: 'download',
        context: 'asbuilt',
        value: this.addressPostcode,
      });
      this.getAndDownloadAsbuilt();
    },
  },
};
</script>

<style>
.download-asbuilt-button {
  text-decoration: none;
  font-size: 0.5rem;
  padding: 0 0.1rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}
.asbuilt-embed {
  width: 1px;
  height: 1px;
  background-color: transparent;
}
</style>
