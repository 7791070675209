<template>
  <div class="wrapper">
    <!-- <label for="wocos">Select a woco:</label> -->
    <select
      name="years"
      class="text-black bg-gray-200 p-1 rounded"
      id="years"
      @change="onChange"
    >
      <option
        v-for="project in projects"
        :key="project"
        :value="project"
        :selected="selectedProject === project"
      >
        {{project}}
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DropDownProject',
  computed: {
    ...mapState(['selectedProject', 'selectedWoco', 'user', 'selectedYear']),
    projects() {
      if (this.selectedWoco === null || this.selectedWoco === undefined) {
        return [];
      }
      const woco = this.user.wocos.find((w) => w.name === this.selectedWoco);

      if (woco === undefined) {
        return [];
      }

      return ['Alle projecten', ...woco.available_years[this.selectedYear]];
    },
  },
  methods: {
    ...mapMutations(['setSelectedProject']),
    onChange({ target }) {
      this.setSelectedProject(target.value);
    },
  },
  mounted() {
    const woco = this.user.wocos.find((w) => w.name === this.selectedWoco);

    if (!this.selectedProject
      || !woco.available_years[this.selectedYear].includes(this.selectedProject)) {
      this.setSelectedProject('Alle Projecten');
    } else {
      this.setSelectedProject(this.selectedProject);
    }
  },
};
</script>
