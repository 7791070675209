const eventsPlugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$events = {
      map: {},

      on(eventName, callback) {
        if (this.map[eventName] === undefined) {
          this.map[eventName] = new Set();
        }
        this.map[eventName].add(callback);
      },

      off(eventName, callback) {
        if (this.map[eventName] === undefined) return;
        this.map[eventName].delete(callback);
      },

      emit(eventName, ...args) {
        if (this.map[eventName] === undefined) return;
        this.map[eventName].forEach((callback) => callback(...args));
      },
    };
  },
};

export default eventsPlugin;
