import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import Vuex from 'vuex';
import * as requests from '@/common/requests';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    user: {
      username: '',
      email: '',
      wocos: [],
      permissions: {},
    },
    userEmail: null,
    isLoggedIn: false,
    availableGroups: [],
    selectedGroup: null,
    selectedWoco: null,
    selectedYear: '2021',
    selectedProject: null,
    batchFilter2021: false,
    documentTemplates: [],
    start: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    resetUser(state) {
      state.user = {
        username: '',
        email: '',
        wocos: [],
        permissions: {},
      };
    },
    setUserEmail(state, userEmail) {
      state.userEmail = userEmail;
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setSelectedWoco(state, value) {
      state.availableGroups = [];
      state.selectedGroup = null;
      state.selectedWoco = value;
    },
    setSelectedYear(state, value) {
      state.availableGroups = [];
      state.selectedGroup = null;
      state.selectedYear = value;
    },
    async setSelectedProject(state, value) {
      const response = await requests.getData(`api/group/woco/${state.selectedWoco}/project/${value}/`);
      state.availableGroups = [];
      state.selectedGroup = null;
      if (response.status === 'OK') {
        console.log(response.data.data.groups)

        state.availableGroups = response.data.data.groups
          .filter((group) => !group.is_private || group.created_by === state.userEmail)
          .filter((group) => group.apps.some((app) => app.name === 'B2B'));
        console.log('state.availableGroups', state.availableGroups)
      }
      state.selectedProject = value;
    },
    setBatchFilter2021(state, value) {
      state.batchFilter2021 = value;
    },
    setDocumentTemplates(state, templates) {
      state.documentTemplates = templates;
    },
    setStart(state, time) {
      state.start = time;
    },
    setSelectedGroup(state, value) {
      state.selectedGroup = value;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});
