<template>
  <span v-if="!selectedTemplate">Geen document template beschikbaar</span>
  <span v-else class="document-template-wrapper" style="display: inline-flex;">
    <b style="margin-right: 3px;">Letter:</b>
    <span
      ref="selectedTemplate"
      class="badge badge-history badge-info pointer"
      style="font-size: 0.7rem; user-select: none;"
      @click="() => onTemplateClick({ target: $refs.selectedTemplate })"
    >
      <i v-if="documentTemplates.length > 1" class="fas fa-caret-down"></i>
      {{ this.selectedTemplate.name }}
    </span>
    <Popover
      v-if="showPopover"
      containerClass="wrapper"
      :align="{ x: 'center' }"
      :target="$refs.selectedTemplate"
    >
      <div class="template-dropdown">
        <div
          v-for="template in documentTemplates"
          :key="`template-${template.id}`"
          @click="() => onTemplateSelect(template)"
          class="badge badge-history badge-info block pointer"
          style="font-size: 0.7rem; margin: 5px 0; display: block;"
        >
          {{ template.name }}
        </div>
      </div>
    </Popover>
    <button
      @click.prevent="onClickDownload"
      class="btn btn-primary download-blob-button"
    >
      Download
    </button>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import Popover from '@/components/Popover.vue';
import * as requests from '@/common/requests';

export default {
  name: 'ClientDocumentTemplate',
  components: {
    Popover,
  },
  props: {
    client: Object,
    rowSelected: Boolean,
  },
  data() {
    return {
      showPopover: false,
      selectedTemplate: { name: '', id: '' },
    };
  },
  computed: {
    ...mapState(['documentTemplates', 'selectedWoco']),
  },
  methods: {
    async onClickDownload() {
      this.$events.emit('interaction', {
        type: 'click',
        context: 'download_letter',
        value: `woco=${this.selectedWoco}&template=${this.selectedTemplate.name}`,
      });
      await this.createLetter();
    },
    onTemplateSelect(template) {
      this.$events.emit('interaction', {
        type: 'click',
        context: 'change_template',
        value: `woco=${this.selectedWoco}&template=${template.name}`,
      });
      this.changeSelectedTemplate(template);
    },
    changeSelectedTemplate(template) {
      this.selectedTemplate = template;
      this.clearPopover();
    },
    generateFileName() {
      const date = new Date();
      const formattedDate = [
        `00${date.getDate()}`.slice(-2),
        `00${date.getMonth() + 1}`.slice(-2),
        date.getFullYear(),
        `00${date.getHours()}`.slice(-2),
        `00${date.getMinutes()}`.slice(-2),
      ].join('_');

      return [
        `${this.selectedWoco}`,
        `${this.client.household.street.replace(/\s/g, '_')}`,
        `${this.client.household.house_number}`,
        `${this.client.household.postal_code.replace(/\s/g, '')}`,
        `${formattedDate}.docx`,
      ].join('_');
    },
    async createLetter(downloadFile = true) {
      const data = {
        client: JSON.parse(JSON.stringify(this.client)),
        template: this.selectedTemplate,
      };

      data.client.people[0].name = data.client.people[0].name.replace('De heer of mevrouw ', '');

      const response = await requests.postDataBlob('POST', `/api/template/${this.selectedWoco}/`, data);
      if (response.status === 'error') {
        console.warn('Failed to create letter');
        return null;
      }
      const blob = response.blob;
      const file = new File([blob], this.generateFileName());

      if (downloadFile) {
        const blobDownloadLink = window.URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = blobDownloadLink;
        anchor.download = this.generateFileName();
        anchor.click();
        window.URL.revokeObjectURL(blobDownloadLink);
        anchor.remove();
      }
      return file;
    },
    setListeners() {
      document.addEventListener('click', this.onTemplateClick);
      this.$events.on('scroll-start', this.clearPopover);
    },
    clearPopover() {
      this.showPopover = false;
      document.removeEventListener('click', this.onTemplateClick);
      this.$events.off('scroll-start', this.clearPopover);
    },
    onTemplateClick({ target }) {
      if (target === this.$refs.selectedTemplate && !this.showPopover) {
        this.showPopover = true;
        setTimeout(() => this.setListeners(), 0); // timeout stops event from firing twice
        return;
      }
      this.clearPopover();
    },
  },
  created() {
    this.selectedTemplate = this.documentTemplates[0];
  },
  beforeDestroy() {
    this.clearPopover();
  },
};
</script>
