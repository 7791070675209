import store from '@/store';

export async function userIsLoggedIn() {
  try {
    const response = await fetch('/api/logged/');
    const json = await response.json();
    return json.data.is_logged_in;
  } catch (err) {
    console.error('Logged status request failed:', err.message);
    return false;
  }
}

export async function requestLogin(email) {
  store.commit('setUserEmail', email);
  try {
    const response = await fetch(`/api/login/${email}/`);
    const json = await response.json();
    return json.status === 'OK';
    // return json.message.toLowerCase() === 'user found, otp send';
  } catch (err) {
    console.error(err.message);
    return false;
  }
}

export async function loginWithOtp(otp) {
  const data = {
    otp,
    email: store.state.userEmail,
  };
  try {
    const response = await fetch('/api/login/otp/', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      store.commit('setLoggedIn', true);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err.message);
    return false;
  }
}

export async function logout() {
  try {
    const response = await fetch(`/api/logout/${store.state.userEmail}`);
    if (response.status === 200) {
      store.commit('setSelectedWoco', null);
      store.commit('setLoggedIn', false);
      store.commit('resetUser');
      // store.commit('setUserEmail', null);
      return true;
    }
    console.log('LOGOUT FAILED');
    return false;
  } catch (err) {
    console.error('LOGOUT ERROR:', err.message);
  }
  return false;
}

export async function verifyUserLoginStatus() {
  const isLoggedIn = await userIsLoggedIn();
  if (isLoggedIn) {
    const response = await fetch('/api/user/details/');
    const jsonResponse = await response.json();
    const user = jsonResponse.data;
    store.commit('setUser', user);
  }
  store.commit('setLoggedIn', isLoggedIn);
}
