var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"statusfilterdiv",staticClass:"status-filter z-50 bg-white",attrs:{"tabindex":"0"},on:{"blur":e => _vm.onBlur(e)}},_vm._l((_vm.options),function([statusName, count]){return _c('div',{key:statusName,class:(
      'status-filter-option '
      + `${_vm.statusColors[statusName]} ${
          _vm.selectedStatus.includes(statusName)
          ? ''
          : 'bolder'
        } `
    ),on:{"click":(e) => _vm.addToSelection(e, statusName)}},[_vm._v(" "+_vm._s(statusName)+" ("+_vm._s(count)+") ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }