<template>
  <div class="page page-login">
    <section class="section">
      <div class="">
        <div class="bg-blue-100 rounded-md p-5" style="max-width: 450px; margin: auto;">
          <h1 class="text-center text-xl mb-5 font-bold">Login</h1>
          <p class="text-left">
            Vul uw e-mailadres in en druk op verstuur.
            Vervolgends ontvangt u een OTP code om mee in te loggen.
          </p>
          <Alert :alerts="alertMessages"/>
          <EmailForm
            v-if="!showOTP"
            :onSubmit="submitEmail"
          />
          <OTPInput
            v-if="showOTP"
            :onSubmit="submitOtp"
            :requestCode="() => submitEmail(userEmail)"
          />
          <Modal v-if="isFetching">
            <Spinner />
          </Modal>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as auth from '@/common/auth';
import EmailForm from '@/components/EmailForm.vue';
import OTPInput from '@/components/OTPInput.vue';
import Modal from '@/components/Modal.vue';
import Spinner from '@/components/Spinner.vue';
import Alert from '@/components/Alert.vue';

export default {
  name: 'LoginView',
  components: {
    OTPInput, Modal, Spinner, EmailForm, Alert,
  },
  data() {
    return {
      showOTP: false,
      loginFailed: false,
      isFetching: false,
      alertMessages: [],
    };
  },
  computed: {
    ...mapState(['userEmail']),
  },
  methods: {
    async submitEmail(email) {
      this.alertMessages = [];
      this.isFetching = true;
      this.showOTP = await auth.requestLogin(email);
      this.isFetching = false;
      if (this.showOTP) {
        this.alertMessages = [
          // { message: 'An OTP code has been sent. Please check your email.', type: 'success' },
          { message: 'OTP code is verstuurd. Controleer uw e-mail.', type: 'success' },
        ];
      } else {
        this.alertMessages = [
          { message: 'Geen gebruiker gevonden met dit e-mail adres.', type: 'danger' },
        ];
      }
    },
    async submitOtp(otp) {
      this.alertMessages = [];
      this.isFetching = true;
      const loginSuccess = await auth.loginWithOtp(otp);
      this.isFetching = false;
      if (loginSuccess) {
        this.$emit('loginChange');
        if (this.$router.currentRoute.query.redirect !== undefined) {
          this.$router.push(this.$router.currentRoute.query.redirect);
        } else {
          this.$router.push({ name: 'Dashboard' });
        }
      } else {
        this.alertMessages = [
          {
            message: 'OTP code incorrect of verlopen.',
            type: 'danger',
          },
        ];
      }
    },
  },
};
</script>
