import store from '@/store';

function getUserInfo() {
  const { user } = store.state;
  if (!user) return
  return {
    name: user.name,
    email: user.email,
  };
}

export function postUserInteraction({ type, context, value }) {
  if (!type || !context || !value) {
    console.groupCollapsed('INTERACTION ERROR');
    console.warn('postUserInteraction: one or more fields haven\'t been provided');
    // eslint-disable-next-line prefer-rest-params
    console.warn('provided', arguments[0]);
    console.warn('expected', { type: 'string', context: 'string', value: 'any' });
    console.trace();
    console.groupEnd();
    return;
  }

  const user = getUserInfo();
  if (!user) return;

  const data = {
    user: user.name,
    event_type: type,
    event_context: context,
    value,
  };

  const options = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  fetch('/api/user/interaction/', options);
}
