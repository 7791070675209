<template>
  <tr
    v-if="client"
    class="client-row z-20"
    :class="`${client.errorFields ? 'table-danger' : ''}`"
  >
    <td>
      <div class="td-container" style="display: flex; align-items: center;">
        <input
          v-if="hasActions"
          type="checkbox"
          v-model="rowSelected"
          style="margin: 0 0.25rem; cursor: pointer;"
          @click="onCheckboxChange"
        >

        <i
          class="fas fa-pencil-alt pointer"
          v-if="canEdit"
          title="Edit"
          @click="() => onEditSelection(client)"
          style="margin: 0 0.25rem; margin-left: auto; font-size: 0.8rem;"
        ></i>
      </div>
    </td>
    <td v-if="!columns[0].hide">
      <div class="td-container">
        <div class="field-with-history">
          <span class="field-with-history-span">{{ client.household.id_woco }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('household.street') ||
          client.modified_fields.includes('household.house_number')"
          :fieldHistory="removePostalCode(getFieldHistory('household.address'))"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          <span :class="filter.street && 'bg-yellow-400 text-white'">{{ client.household.street }}</span>
          <span :class="filter.houseNumber && 'bg-yellow-400 text-white'"> {{ client.household.house_number }}</span>
        </FieldWithHistory>
        <div v-else>
          <span :class="filter.street && 'bg-yellow-400 text-white'">{{ client.household.street }}</span>
          <span :class="filter.houseNumber && 'bg-yellow-400 text-white'"> {{ client.household.house_number }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('household.postal_code')"
          :fieldHistory="getFieldHistory('household.postal_code')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          <span :class="filter.postCode && 'bg-yellow-400 text-white'">{{ client.household.postal_code }}</span>
        </FieldWithHistory>
        <div v-else>
          <span :class="filter.postCode && 'bg-yellow-400 text-white'">{{ client.household.postal_code }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('household.city')"
          :fieldHistory="getFieldHistory('household.city')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          {{ client.household.city }}
        </FieldWithHistory>
        <div v-else>
          {{ client.household.city }}
        </div>
      </div>
    </td>
    <td
      v-if="hasCountryColumn"
      :title="(
        (
          client.errorFields
            && client.errorFields.household
            && client.errorFields.household.country
        ) ? client.errorFields.household.country.message : ''
      )"
    >
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('household.country')"
          :fieldHistory="getFieldHistory('household.country')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          {{ client.household.country }}
        </FieldWithHistory>
        <div v-else>
          {{ client.household.country }}
        </div>
      </div>
    </td>
    <td v-if="client.groups">
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('groups')"
          :fieldHistory="getFieldHistory('groups ')"
          :onHistoryShow="() => loadFieldHistory(client.groups)"
        >
          {{ client.groups.join(', ') }}
        </FieldWithHistory>
        <div v-else>
          {{ client.groups.join(', ') }}
        </div>
      </div>
    </td>
    <td>
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('person.name')"
          :fieldHistory="getFieldHistory('person.name')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          {{ client.people?.map(person => person.name).join('; ') || '' }}
        </FieldWithHistory>
        <div v-else>
          {{ client.people?.map(person => person.name).join('; ') || '' }}
        </div>
      </div>
    </td>
    <td>
      <div class="td-container" style="overflow-wrap: anywhere;">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('email.address')"
          :fieldHistory="getFieldHistory('email.address')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          {{ client.email.address }}
        </FieldWithHistory>
        <div v-else>
          {{ client.email.address }}
        </div>
      </div>
    </td>
    <td>
      <div class="td-container">
        <FieldWithHistory
          v-if="hasHistory"
          :isNew="client.modified_fields.includes('phone.number_text')"
          :fieldHistory="getFieldHistory('phone.number_text')"
          :onHistoryShow="() => loadFieldHistory(client.household)"
        >
          {{ client.phones?.map(phone => phone.number_text).join('; ') || '' }}
        </FieldWithHistory>
        <div v-else>
          {{ client.phones?.map(phone => phone.number_text).join('; ') || '' }}
        </div>
      </div>
    </td>
    <td class="p-1" v-if="hasTaskData">
      <StatusDropdown
        :taskData="taskData"
        :selectedStatusHistory="selectedStatusHistory"
        :statusColors="statusColors"
        :onSelectStatusHistory="(status) => showStatusHistoryInfo(status)"
      />
    </td>
    <td v-if="hasTaskData" style="vertical-align: middle;" class="info-column">
      <div class="td-container">
        <StatusBadge :label="taskData[selectedStatusHistory]?.status || ''"/>
        <span v-if="(
          selectedStatusHistory === 'design'
          && client.task_data.design !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images.find(i => i.type === 'panel_configuration')
        )">
          Panel configuratie:
          <button
            class="btn btn-primary download-button"
            @click="() => onClickImage(
              client.task_data.design.images.find(i => i.type === 'panel_configuration').url
            )"
          >View image</button>
          <!-- <img
            :src="client.task_data.design.images.find(i => i.type === 'panel_configuration').url"
            style="height: 14px; padding: 0; margin-right: 5px;"
            class="img-thumbnail pointer"
            > -->
        </span>
        <span v-if="(
          selectedStatusHistory === 'design'
          && client.task_data.design !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images.find(i => i.type === 'street_view')
        )">
          Street view:
          <button
          class="btn btn-primary download-button"
            @click="() => onClickImage(
              client.task_data.design.images.find(i => i.type === 'street_view').url,
              [client.task_data.design.images.find(i => i.type === 'street_view').url]
            )"
          >View image</button>
        </span>
        <span v-if="(
          selectedStatusHistory === 'design'
          && client.task_data.design !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images !== undefined
          && client.task_data.design.images.find(i => i.type === 'ballast')
        )">
          Ballastplan:
          <button
          class="btn btn-primary download-button"
            @click="() => onClickImage(
              client.task_data.design.images.find(i => i.type === 'ballast').url,
              [client.task_data.design.images.find(i => i.type === 'ballast').url]
            )"
          >View image</button>
        </span>
        <span
          v-if="(
            selectedStatusHistory === 'installation'
            && client.task_data['installation'] !== undefined
            && client.task_data['installation'].reject_type !== undefined
          )"
          style="padding-right: 0.1rem;"
        >
          <b>Reject type:</b>
          {{ client.task_data['installation'].reject_type }}
        </span>
        <span
          v-if="(
            selectedStatusHistory === 'design'
            && client.task_data['design'] !== undefined
            && client.task_data['design'].reason !== undefined
          )"
          style="padding-right: 0.1rem;"
        >
          <b>Reason:</b>
          {{ client.task_data['design'].reason }}
        </span>
        <DateBadge
          v-show="(
            selectedStatusHistory === 'installation'
            && client.task_data.installation !== undefined
            && client.task_data.installation.appointment_date !== undefined
          )"
          :label="(
            (client.task_data.installation !== undefined
            && client.task_data.installation.appointment_date !== undefined)
            ? client.task_data.installation.appointment_date
            : ''
          )"
          :badgeColor="(
            (client.task_data.installation !== undefined
            && client.task_data.installation.status !== undefined
            && ['gecontroleerd', 'gebouwd'].includes(client.task_data.installation.status)
            )
            ? 'darkolivegreen'
            : 'goldenrod'
          )"
        />
        <DateBadge
          v-show="(
            selectedStatusHistory === 'inspection'
            && client.task_data.inspection !== undefined
            && client.task_data.inspection.appointment_date !== undefined
          )"
          :label="(
            (client.task_data.inspection !== undefined
            && client.task_data.inspection.appointment_date !== undefined)
            ? client.task_data.inspection.appointment_date
            : ''
          )"
          :badgeColor="(
            (client.task_data.inspection !== undefined
            && client.task_data.inspection.status !== undefined
            && ['gecontroleerd', 'gebouwd'].includes(client.task_data.inspection.status)
            )
            ? 'darkolivegreen'
            : 'goldenrod'
          )"
        />
         <span
          v-show="selectedStatusHistory === 'installation'"
        >
          <span
            v-show="(
              client.task_data['installation'] !== undefined
              && client.task_data['installation'].asbuilt !== undefined
            )"
            class="asbuilt-component">
            <b>Asbuilt</b>:
          </span>
          <DownloadAsbuiltButton
            v-show="canDownloadAsbuilt(client)"
            :ref="canDownloadAsbuilt(client) ? 'downloadasbuiltbutton' : ''"
            :asbuiltFilename="(
              client.task_data['installation'] === undefined
              ? undefined
              : client.task_data['installation'].asbuilt
            )"
            :addressPostcode="(
              `${client.household.street}_${client.household.house_number}`
              + `_${client.household.postal_code}`
            )"
          />
          <span v-html="products" style="padding-right: 0.1rem;" />
        </span>
        <template
          v-if="(
            selectedStatusHistory === 'design'
            && client.task_data.design !== undefined
            && client.task_data.design.extra_fields !== undefined
            && Object.entries(client.task_data.design.extra_fields).length > 0
        )">
          <DateBadge
            :label="Object.entries(client.task_data.design.extra_fields)
              .map(fields => fields.join(': '))
              .join(', ')"
            badgeColor="DarkSlateGrey"
          />
        </template>
        <template
          v-if="(
            selectedStatusHistory === 'design'
            && client.task_data.design !== undefined
            && client.task_data.design.products !== undefined
        )">
          <DateBadge
            v-for="product in client.task_data.design.products"
            :key="product.code"
            :label="Object.values(product).join(', ')"
            badgeColor="DarkSalmon"
          />
        </template>
        <template v-if="
          selectedStatusHistory === 'installation'
          && client.task_data.installation !== undefined
          && client.task_data.installation.tickets
          && Object.keys(client.task_data.installation.tickets).length > 0"
        >
          <template
            v-if="(
              Object.values(client.task_data.installation.tickets)[0].output
              && Object.values(client.task_data.installation.tickets)[0].output.products
          )">
            <DateBadge
              v-for="product in formatProducts(
                Object.values(client.task_data.installation.tickets)[0].output.products
              )"
              :key="product.code"
              :label="Object.values(product).join(', ')"
              badgeColor="LightSeaGreen"
            />
          </template>
          <template
            v-if="
              ticketOutputImages(selectedStatusHistory)
              && ticketOutputImages(selectedStatusHistory).length > 0
            "
          >
            <span
              style="padding-right: 2px;"
            >
            <button
              class="btn btn-primary download-button"
               @click="() => onClickImage(
                  ticketOutputImages(selectedStatusHistory)[0].url,
                  ticketOutputImages(selectedStatusHistory)
                    .map(image => image.url)
                )"
            >Images</button>
            </span>
            <span
              v-if="getServices(client.task_data.installation.tickets).length > 0"
              style="margin-right: 2em;"
            >
              <b style="padding-right: 0.5em;">Services: </b>
              {{getServices(client.task_data.installation.tickets).join(', ')}}
            </span>
            <!-- not working?? -->
            <span
              v-if="getAdditionalWorks(client.task_data.installation.tickets).length > 0"
              style="margin-right: 2em;"
            >
              <b style="padding-right: 0.5em;">Meer werk: </b>
              {{getAdditionalWorks(client.task_data.installation.tickets).join(', ')}}
            </span>
          </template>

        </template>
        <template v-if="
          selectedStatusHistory === 'inspection'
          && client.task_data.inspection !== undefined
          && client.task_data.inspection.tickets
          && Object.keys(client.task_data.inspection.tickets).length > 0"
        >
          <template
            v-if="
              ticketOutputImages(selectedStatusHistory)
              && ticketOutputImages(selectedStatusHistory).length > 0
            "
          >
            <span
              style="padding-right: 2px;"
            >
            <button
              class="btn btn-primary download-button"
               @click="() => onClickImage(
                  ticketOutputImages(selectedStatusHistory)[0].url,
                  ticketOutputImages(selectedStatusHistory)
                    .map(image => image.url)
                )"
            >Images</button>
            </span>
          </template>

        </template>
        <span v-show="(
          selectedStatusHistory === 'sales'
        )"
        >
          <template v-if="(
            client.task_data['sales'] !== undefined
            && client.task_data['sales'].contract_type !== undefined
          )">
            <b>Akkoordverklaring</b>:
            <ContractComponent
              :contract="(
                client.task_data['sales'] === undefined
                ? undefined
                : {
                    type: client.task_data['sales'].contract_type,
                    id: client.task_data['sales'].signrequest_document_uuid,
                    url: client.task_data['sales'].letter_contract
                  }
              )"
              :address="address"
            >
            </ContractComponent>
            <DownloadBlobButton
              v-show="canDownloadContract(client, 'digital')"
              style="margin-right: 7px;"
              ref="downloadblobbutton"
              :contract="(
                client.task_data['sales'] === undefined
                ? undefined
                : {
                    type: client.task_data['sales'].contract_type,
                    id: client.task_data['sales'].signrequest_document_uuid,
                    url: client.task_data['sales'].letter_contract,
                    file_name: client.task_data['sales'].letter_contract !== undefined ? client.task_data['sales'].letter_contract.split('/').pop() : undefined
                  }
              )"
              :address="this.client.household.address"
            />
            <DownloadBlobButton
              v-show="canDownloadContract(client, 'letter')"
              style="margin-right: 7px;"
              :ref="
                canDownloadContract(client, 'letter')
                ? 'downloadimagebutton'
                : ''
              "
              :contract="(
                client.task_data['sales'] === undefined
                ? undefined
                : {
                    type: client.task_data['sales'].contract_type,
                    id: client.task_data['sales'].signrequest_document_uuid,
                    url: client.task_data['sales'].letter_contract,
                    file_name: client.task_data['sales'].letter_contract !== undefined ? client.task_data['sales'].letter_contract.split('/').pop() : undefined
                  }
              )"
              :downloadLink="client.task_data['sales'].letter_contract"
              :address="this.client.household.address"
            />
            <template>
              <DateBadge
                v-if="client.task_data.sales.contract_type"
                :label="`contract type: ${client.task_data.sales.contract_type}`"
                badgeColor="DarkSalmon"
              />
              <DateBadge
                v-if="client.task_data.sales.letter_panel_quantity"
                :label="`letter panel quantity: ${client.task_data.sales.letter_panel_quantity}`"
                badgeColor="DarkSalmon"
              />
              <DateBadge
                v-if="client.task_data.sales.panel_quantity"
                :label="`panel quantity: ${client.task_data.sales.panel_quantity}`"
                badgeColor="DarkSalmon"
              />
            </template>
          </template>
        </span>
        <span
          v-if="(
            ['sales', 'design'].includes(selectedStatusHistory)
            && client.people.length > 0
          )"
        >
          <ClientDocumentTemplate
            v-if="!!user.permissions['download_docs']"
            ref="clientDocumentTemplate"
            :client="client"
            :rowSelected="rowSelected"
          />
        </span>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';
import * as requests from '@/common/requests';
import { getNestedKey } from '@/common/convert';

import FieldWithHistory from '@/components/FieldWithHistory.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import DateBadge from '@/components/DateBadge.vue';
import StatusDropdown from '@/components/StatusDropdown.vue';
import ContractComponent from '@/components/ContractComponent.vue';
import DownloadBlobButton from '@/components/DownloadBlobButton.vue';
import DownloadAsbuiltButton from '@/components/DownloadAsbuiltButton.vue';
import ClientDocumentTemplate from '@/components/ClientDocumentTemplate.vue';

export default {
  name: 'ClientRow',
  components: {
    FieldWithHistory,
    StatusBadge,
    DateBadge,
    StatusDropdown,
    ContractComponent,
    DownloadBlobButton,
    DownloadAsbuiltButton,
    ClientDocumentTemplate,
  },
  props: {
    index: Number,
    columns: Array,
    client: Object,
    filter: Object,
    onEditSelection: Function,
    onClickImage: Function,
    filterLength: Number,
    canEdit: Boolean,
    hasTaskData: Boolean,
    filterStatusHistory: String,
    onSelectRow: Function,
    onShiftClickRow: Function,
    setSelectionRange: Function,
    rowSelection: Array,
    hasHistory: Boolean,
    hasActions: Boolean,
    serviceCatalog: Object,
    additionalWorkCatalog: Object,
    asbuiltFilename: String,
    addressPostcode: String,
    showGroupsColumn: Boolean,
  },
  data() {
    return {
      selected: null,
      statusColors: {
        Installatie: 'green',
        Schouw: 'yellow',
        Ontwerp: 'gray',
        Akkoord: 'blue',
      },
      fieldHistory: null,
      statusHistory: undefined,
      rowSelected: false,
      showAsbuilt: false,
    };
  },
  watch: {
    filterStatusHistory(value) {
      this.statusHistory = value;
    },
  },
  computed: {
    ...mapState(['userEmail', 'isLoggedIn', 'user', 'documentTemplates', 'selectedWoco']),
    address() {
      return `${this.client.household.street} ${this.client.household.house_number}`;
    },
    taskData() {
      return this.client.task_data;
    },
    selectedStatusHistory() {
      return (
        this.statusHistory === undefined
          ? this.taskData.status
          : this.statusHistory
      );
    },
    images() {
      if (this.taskData.design === undefined) return false;
      if (this.taskData.design.panel_configuration === undefined) return false;
      return [this.taskData.design.panel_configuration];
    },
    products() {
      let result = '';
      if (this.taskData.installation === undefined) {
        return '';
      }
      const products = this.taskData.installation.products;
      if (!products) return '';
      if (!!products.inverter && !!products.inverter.description) {
        result += `<b>Omvormer</b>: ${products.inverter.description} (${products.inverter.amount})`;
      }
      if (!!products.panel && !!products.panel.description) {
        result += `&nbsp;<b>Paneel</b>: ${products.panel.description} (${products.panel.amount})`;
      }
      return result;
    },
    hasCountryColumn() {
      return this.columns.some((col) => col.field === 'household.country');
    },
  },
  methods: {
    ticketOutputImages(ticketType) {
      if (
        this.client.task_data
        && this.client.task_data[ticketType]
        && this.client.task_data[ticketType].tickets
      ) {
        const ticketList = Object.values(this.client.task_data[ticketType].tickets);
        if (
          ticketList.length > 0
          && ticketList[0].output
          && ticketList[0].output.images
        ) {
          return ticketList[0].output.images.filter((img) => img.url !== null);
        }
      }
      return null;
    },
    formatProducts(products) {
      const newProducts = [];

      Object.entries(products).forEach(([type, value]) => {
        if (!value) return;

        Object.entries(value).forEach(([code, prod]) => {
          const newProduct = {
            type,
          };

          const amount = prod.amount;
          const productType = prod.description;

          if (productType) {
            newProduct.description = productType;
          }
          if (amount) {
            newProduct.amount = amount;
          }

          newProducts.push(newProduct);
        });
      });

      return newProducts;
    },
    getServices(tickets) {
      const services = [];

      Object.values(tickets).forEach((ticket) => {
        if (!ticket.output) return;

        if (ticket.output.services) {
          if (ticket.output.services.service) {
            services.push(this.serviceCatalog[ticket.output.services.service]);
          }
        }
      });

      return services;
    },
    getAdditionalWorks(tickets) {
      const additionalWorks = [];

      Object.values(tickets).forEach((ticket) => {
        if (!ticket.output) return;

        if (ticket.output.services) {
          if (ticket.output.services.additional_work) {
            ticket.output.services.additional_work.forEach((additionalWork) => {
              additionalWorks.push(this.additionalWorkCatalog[additionalWork]);
            });
          }
        }
      });

      return additionalWorks;
    },
    canDownloadAsbuilt(client) {
      return (
        client.task_data.installation !== undefined
        && client.task_data.installation.asbuilt !== undefined
      );
    },
    canDownloadContract(client, contractType) {
      return (
        client.task_data.sales !== undefined
        && client.task_data.sales.contract_type === contractType
      );
    },
    addSpan(input, tokenRegExp) {
      if (!tokenRegExp) {
        return input;
      }
      return input.replace(tokenRegExp, `<span class="bg-primary text-white">${'$&'}</span>`);
    },
    async loadFieldHistory(household) {
      this.$events.emit('interaction', {
        type: 'hover',
        context: 'field_history',
        value: `address=${household.address}`,
      });
      if (!this.fieldHistory) {
        const response = await requests.getFieldHistoryForHousehold(household.address);
        if (response.status === 'OK') {
          this.fieldHistory = response.json.data.history;
        }
      }
    },
    getFieldHistory(nestedKey) {
      return getNestedKey(this.fieldHistory, nestedKey, []);
    },
    removePostalCode(fieldHistory) {
      return fieldHistory.map((change) => {
        const value = change.value.replace(/[\d+]{4}\s*[\w]{1,2}$/, '');
        return { ...change, value };
      });
    },
    showStatusHistoryInfo(status) {
      this.statusHistory = status;
    },
    onShiftClick(){
      this.onShiftClickRow(this.index);
    },

    onNormalClick(){
      this.setSelectionRange(this.index);
      // Sales: akkoordverklaring
      let downloadContract;
      if (this.client.task_data.sales !== undefined) {
        downloadContract = (
          this.$refs.downloadblobbutton !== undefined
            ? this.$refs.downloadblobbutton.onClick
            : () => {}
        );
      }

      // if (
      //   this.client.task_data.sales !== undefined
      //   && this.client.task_data.sales.contract_type === 'letter'
      // ) {
      //   downloadContract = (
      //     this.$refs.downloadimagebutton !== undefined
      //       ? () => { this.$refs.downloadblobbutton.onClick(); }
      //       // ? () => (
      //       //   {
      //       //     address: this.address,
      //       //     url: this.client.task_data.sales.letter_contract,
      //       //     id: this.client.task_data.sales.signrequest_document_uuid,
      //       //   }
      //       // )
      //       : () => {}
      //   );
      // } else {
      //   downloadContract = (
      //     this.$refs.downloadblobbutton !== undefined
      //       ? () => { this.$refs.downloadblobbutton.onClick(); }
      //       // ? () => (
      //       //   {
      //       //     address: this.address,
      //       //     url: this.client.task_data.sales.letter_contract,
      //       //     id: this.client.task_data.sales.signrequest_document_uuid,
      //       //   }
      //       // )
      //       : () => {}
      //   );
      // }

      // Installation: asbuilt
      let downloadAsbuilt;
      if (
        this.client.task_data.installation !== undefined
        && this.client.task_data.installation.asbuilt !== undefined
      ) {
        downloadAsbuilt = this.$refs.downloadasbuiltbutton !== undefined
          ? () => this.$refs.downloadasbuiltbutton.getAndDownloadAsbuilt()
          : () => {};
      }

      const downloadLetter = this.$refs.clientDocumentTemplate !== undefined
        ? () => this.$refs.clientDocumentTemplate.createLetter(false)
        : () => {};

      const applyTemplate = this.$refs.clientDocumentTemplate !== undefined
        ? (template) => this.$refs.clientDocumentTemplate.changeSelectedTemplate(template)
        : () => {};

      this.onSelectRow(
        this.client.household.address,
        downloadContract,
        downloadAsbuilt,
        downloadLetter,
        applyTemplate,
      );
    },

    onCheckboxChange(event) {
      if (event && event.shiftKey) {
        this.onShiftClick();
      } else {
        this.onNormalClick();
      }  
    },

    onSelectAll(isSelected) {
      if (this.rowSelected !== isSelected) {
        this.rowSelected = isSelected;
        this.onCheckboxChange();
      }
    },
  },
  mounted() {
    if (this.filterStatusHistory && this.client.task_data[this.filterStatusHistory] !== undefined) {
      this.statusHistory = this.filterStatusHistory;
    }
  },
};
</script>

<style scoped lang="postcss">
.download-button {
  text-decoration: none;
  font-size: 0.5rem;
  padding: 0 0.1rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.error {
  background-color: red !important;
}

.asbuilt-component {
  cursor: pointer;
}
.view-asbuilt-button {
  text-decoration: none;
  font-size: 0.5rem;
  padding: 0 0.1rem;
  margin-left: 0.25rem;
}

.info-column > div {
  align-items: center;
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
  }
}

.info-column {
  overflow-x: auto;
  white-space: nowrap;
  padding-right: 0.25rem;
}

.info-column::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  color: #868686; /* Fallback */
}

.info-column::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.info-column::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
</style>
