<template>
  <div>
    <div class="fixed top-0 left-0 bg-black opacity-80 z-20 w-full h-full"></div>
    <div
      class="fixed inset-0 flex justify-center z-30 overflow-scroll"
      ref="outer"
    >
      <div>
        <button
          v-if="onClose"
          @click="onClose"
          class="close absolute text-xl bg-white p-3 rounded hover:bg-red-200"
          style="z-index: 1; top: 10px; right: 10px;"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="max-w-3xl flex items-center min-h-full">
        <slot />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Modal',
  props: {
    displayButton: Boolean,
    onClose: Function,
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'Escape' && this.onClose) {
        window.removeEventListener('keydown', this.onKeyDown);
        this.onClose();
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
};
</script>
