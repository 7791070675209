<template>
  <form>
    <div class="flex flex-row mt-3">
      <div class="flex-grow">
        <input v-model="email" class="p-2 w-full rounded-l" placeholder="Email" />
      </div>

      <div>
        <button
          type="button"
          @click="onSubmit(email)"
          class="bg-ecorus w-full h-full p-2 rounded-r text-white"
        >
          Verstuur
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmailForm",
  props: {
    onSubmit: Function,
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapState(["userEmail"]),
  },
  created() {
    this.email = this.userEmail || "";
  },
};
</script>
