import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { EventsPlugin } from './plugins';
import { VueQueryPlugin } from "@tanstack/vue-query";

Vue.config.productionTip = false;

Vue.use(EventsPlugin);
Vue.use(VueQueryPlugin)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
