<template>
  <div class="big-kpi" :style="`background-color: ${color};`">
    <div class="big-kpi-inner">
      <div class="big-kpi-value">{{ kpi.value }}</div>
      <div class="big-kpi-name">{{ kpi.name }}</div>
      <div class="big-kpi-value">
        {{ isNaN(kpi.percentage) ? "0.0" : kpi.percentage.toFixed(1) }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    kpi: Object,
    color: String,
    type: String,
  },
  methods: {
    gotoClientInfo(type) {
      this.$router.push({
        name: "Gegevens",
        query: {
          status: type,
        },
      });
    },
  },
};
</script>

<style>
.big-kpi {
  color: whitesmoke;
  cursor: pointer;
  display: flex;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 1em 2em -0.25em rgba(10, 10, 10, 0.25), 0 0 0 1px rgba(10, 10, 10, 0.02);
  transition: all 0.15s ease-out;
}

.big-kpi:hover {
  transform: translateY(-2px) scale(1.005) translateZ(0);
  box-shadow: 0 2em 4em -0.5em rgba(10, 10, 10, 0.25), 0 0 0 2px rgba(10, 10, 10, 0.02);
  cursor: default;
}

.big-kpi-inner {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.big-kpi-name {
  font-size: 2rem;
}

.big-kpi-value {
  font-size: 3rem;
}

.big-kpi-info {
  display: flex;
  flex-direction: row;
}

.big-kpi-info-column {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}
</style>
